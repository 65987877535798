<script>
import Vue from 'vue';
import GenderEnum from '~/enums/GenderEnum';
import EnumSelect from '~/components/EnumSelect/EnumSelect';

export default Vue.extend({
  extends: EnumSelect,

  data: () => ({
    resources: GenderEnum.all(),
  }),
});
</script>
