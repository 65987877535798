class RegistrationCodeStateEnum {
  static ACTIVE = 'active';
  static INACTIVE = 'inactive';

  static labelKey(item: string) {
    return RegistrationCodeStateEnum.meta(item)?.labelKey;
  }

  static meta(item: string) {
    switch (item) {
      case RegistrationCodeStateEnum.ACTIVE:
        return {
          labelKey: 'global.statuses.active',
        };
      case RegistrationCodeStateEnum.INACTIVE:
        return {
          labelKey: 'global.statuses.inactive',
        };
    }
  }

  static all() {
    return [
      {
        key: RegistrationCodeStateEnum.ACTIVE,
        labelKey: RegistrationCodeStateEnum.labelKey(RegistrationCodeStateEnum.ACTIVE),
      },
      {
        key: RegistrationCodeStateEnum.INACTIVE,
        labelKey: RegistrationCodeStateEnum.labelKey(RegistrationCodeStateEnum.INACTIVE),
      },
    ];
  }
}

export default RegistrationCodeStateEnum;
