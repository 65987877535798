class GenderEnum {
  static MALE = 'male';
  static FEMALE = 'female';
  static OTHER = 'other';

  static labelKey(item: string) {
    return GenderEnum.meta(item)?.labelKey;
  }

  static icon(item: string) {
    return GenderEnum.meta(item)?.icon;
  }

  static meta(item: string) {
    switch (item) {
      case GenderEnum.MALE:
        return {
          labelKey: 'global.genders.male',
          icon: 'male',
        };
      case GenderEnum.FEMALE:
        return {
          labelKey: 'global.genders.female',
          icon: 'female',
        };
      case GenderEnum.OTHER:
        return {
          labelKey: 'global.genders.other',
          icon: 'transgender-alt',
        };
    }
  }

  static all() {
    return [
      {
        key: GenderEnum.MALE,
        labelKey: GenderEnum.labelKey(GenderEnum.MALE),
      },
      {
        key: GenderEnum.FEMALE,
        labelKey: GenderEnum.labelKey(GenderEnum.FEMALE),
      },
      {
        key: GenderEnum.OTHER,
        labelKey: GenderEnum.labelKey(GenderEnum.OTHER),
      },
    ];
  }
}

export default GenderEnum;
