<template>
  <t-split-screen wide centered>
    <template v-if="tokenIsMissing">
      <div>
        <validation-observer ref="validator" v-slot="{ invalid, handleSubmit }" tag="div">
          <h1>{{ $t('pages.login.heading', { serviceName: $config.public.service.name }) }}</h1>
          <p class="text--grey">
            {{ $t('pages.applicant.register.missing_token_lead') }}
          </p>
          <t-form class="mt-5" @submit.native.prevent="handleSubmit(tokenSubmit)">
            <t-validation-wrapper
              vid="link"
              :name="$t('fields.invitation_link')"
              :label="$t('fields.invitation_link')"
              rules="required|invitation_link"
            >
              <t-input
                v-model="invitationLink"
                type="text"
                :placeholder="$t('fields.invitation_link_placeholder')"
              />
            </t-validation-wrapper>

            <t-form-element>
              <t-button-loading
                native-type="submit"
                block
                :loading="verifyingToken"
                :disabled="invalid"
              >
                {{ $t('global.actions.submit') }}
              </t-button-loading>
            </t-form-element>
          </t-form>
        </validation-observer>
      </div>
      <dashboard-image slot="secondary" name="login" />
    </template>
    <template v-else>
      <div>
        <validation-observer ref="validator" v-slot="{ invalid, handleSubmit }" tag="div">
          <h1>{{ $t('pages.applicant.register.heading') }}</h1>
          <p class="text--grey">
            {{ $t('pages.applicant.register.lead') }}
          </p>

          <t-form class="mt-5" @submit.native.prevent="handleSubmit(submit)">
            <t-grid>
              <t-grid-row>
                <t-grid-cell>
                  <t-validation-wrapper
                    vid="firstname"
                    :name="$t('fields.first_name')"
                    :label="$t('fields.first_name')"
                    rules="required"
                  >
                    <t-input
                      v-model="form.firstname"
                      type="text"
                      :placeholder="$t('fields.first_name')"
                    >
                      <t-icon slot="right" :icon="['fas', 'user']" />
                    </t-input>
                  </t-validation-wrapper>
                </t-grid-cell>
                <t-grid-cell>
                  <t-validation-wrapper
                    vid="lastname"
                    :name="$t('fields.last_name')"
                    :label="$t('fields.last_name')"
                    rules="required"
                  >
                    <t-input
                      v-model="form.lastname"
                      type="text"
                      :placeholder="$t('fields.last_name')"
                    >
                      <t-icon slot="right" :icon="['fas', 'user']" />
                    </t-input>
                  </t-validation-wrapper>
                </t-grid-cell>
              </t-grid-row>
            </t-grid>

            <t-validation-wrapper
              vid="email"
              :name="$t('fields.email_address')"
              rules="required|email"
            >
              <t-checked-email v-model="form.email" />
            </t-validation-wrapper>

            <t-validation-wrapper
              vid="password"
              :name="$t('fields.password.password')"
              rules="required|min:8|password"
            >
              <t-input
                v-model="form.password"
                native-type="password"
                :placeholder="`${$t('fields.password.password')}...`"
              >
                <t-icon slot="right" :icon="['fas', 'lock']" />
              </t-input>
            </t-validation-wrapper>

            <t-validation-wrapper
              vid="confirmation"
              :name="$t('fields.password.confirmation')"
              rules="required|confirmed:password"
            >
              <t-input
                v-model="confirmation"
                native-type="password"
                :placeholder="`${$t('fields.password.confirmation')}...`"
              >
                <t-icon slot="right" :icon="['fas', 'lock']" />
              </t-input>
            </t-validation-wrapper>

            <t-validation-wrapper vid="gender" :name="$t('fields.gender')" rules="required|string">
              <gender-select v-model="form.gender" />
            </t-validation-wrapper>

            <t-validation-wrapper
              :name="$t('fields.privacy_policy')"
              :rules="{ required: { allowFalse: false } }"
              no-label
            >
              <t-checkbox v-model="privacyAccepted">
                <i18n path="fields.privacy_policy_accept" tag="span">
                  <a :href="$config.public.service.applicant_privacy_url" target="_blank">{{
                    $t('fields.privacy_policy')
                  }}</a>
                </i18n>
              </t-checkbox>
            </t-validation-wrapper>

            <t-form-element>
              <t-button-loading native-type="submit" block :loading="loading" :disabled="invalid">
                {{ $t('global.actions.register') }}
              </t-button-loading>
            </t-form-element>
          </t-form>
        </validation-observer>
      </div>

      <p class="text--centered">
        {{ $t('pages.applicant.register.existing_account_lead') }}
        <nuxt-link :to="{ name: 'login' }">
          {{ $t('global.actions.login') }}
        </nuxt-link>
      </p>

      <dashboard-image slot="secondary" name="register-applicant" />
    </template>
  </t-split-screen>
</template>

<script>
import Vue from 'vue';
import TValidationWrapper from '~/components/TValidationWrapper.vue';
import TCheckedEmail from '~/components/Fields/TCheckedEmail';
import DashboardImage from '~/components/DashboardImage.vue';
import GenderSelect from '~/components/EnumSelect/GenderSelect.vue';
import UserTypeEnum from '~/enums/UserTypeEnum';
import RegistrationCode from '~/models/RegistrationCode';
import RegistrationCodeStateEnum from '~/enums/RegistrationCodeStateEnum';
import { guessInvitationToken } from '~/utils';

export default Vue.extend({
  components: {
    TValidationWrapper,
    TCheckedEmail,
    DashboardImage,
    GenderSelect,
  },

  layout: 'standalone',
  middleware: 'guest',

  data() {
    return {
      form: {
        firstname: null,
        lastname: null,
        email: null,
        password: null,
        gender: null,
        timezone: this.$dayjs.tz.guess(),
        registration_token: null,
      },

      tokenIsMissing: false,
      verifyingToken: false,
      invitationLink: null,
      confirmation: null,
      privacyAccepted: false,
      loading: false,
    };
  },

  fetch() {
    // Maintain compatibility with the old query interface
    const token = guessInvitationToken(this.$route.fullPath);

    if (!token) {
      this.tokenIsMissing = true;
      return;
    }

    this.verifyToken(token, true);
  },

  methods: {
    getMessageForInvalidState(state) {
      switch (state) {
        case RegistrationCodeStateEnum.INACTIVE:
          return this.$t('notifications.registration_code.deactivated');
      }

      return '';
    },

    async submit() {
      this.loading = true;
      try {
        await this.$auth.register({
          userType: UserTypeEnum.APPLICANT,
          payload: this.form,
        });
        await this.$router.pushExpectRedirect({ name: this.$auth.getOnboardingRoute() });
      } catch (e) {
        this.$axios.handleError(e, this.$refs.validator);
      } finally {
        this.loading = false;
      }
    },

    tokenSubmit() {
      this.verifyToken(guessInvitationToken(this.invitationLink));
    },

    async verifyToken(token, isInitialCall = false) {
      this.verifyingToken = true;
      try {
        const registrationCode = await RegistrationCode.$findByToken(token);

        if (registrationCode.state !== RegistrationCodeStateEnum.ACTIVE) {
          const message = this.getMessageForInvalidState(registrationCode.state);
          await this.$notify.error(message);
          return;
        }

        this.form.registration_token = token;
        this.$router.pushExpectRedirect({ name: this.$route.name, query: { token } });
        this.tokenIsMissing = false;
      } catch (e) {
        if ([404, 422].includes(e.response.status)) {
          this.$notify.warning({
            title: this.$t('pages.applicant.register.no_association_warning'),
            timeout: isInitialCall ? 0 : 5000,
          });
          this.tokenIsMissing = true;
          this.$router.pushExpectRedirect({ name: this.$route.name });
          return;
        }
        this.$axios.handleError(e);
      } finally {
        this.verifyingToken = false;
      }
    },
  },
});
</script>
