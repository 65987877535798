<template>
  <div class="t-checked-email">
    <t-input
      v-model="computedValue"
      type="email"
      :placeholder="`${$t('fields.email_address')}...`"
      @blur="handleBlur"
      @focus="$emit('focus', $event)"
    >
      <t-icon slot="right" :icon="['far', 'envelope']" />
    </t-input>
    <i18n v-if="suggestion" path="fields.email_suggestion" class="text--grey">
      <a href="#" @click="applySuggestion">{{ suggestion }}</a>
    </i18n>
  </div>
</template>

<script>
import Mailcheck from 'mailcheck';

export default {
  props: {
    value: {
      type: String,
      required: false,
      default: '',
    },
  },

  data() {
    return {
      newValue: this.value,
      suggestion: null,
    };
  },

  computed: {
    computedValue: {
      get() {
        return this.newValue;
      },

      set(value) {
        this.newValue = value;
        this.$emit('input', value);
      },
    },
  },

  watch: {
    value(value) {
      this.newValue = value;
    },
  },

  methods: {
    applySuggestion() {
      this.computedValue = this.suggestion;
      this.clearSuggestion();
    },

    handleBlur($event) {
      this.suggestion = this.suggest(this.computedValue);

      this.$emit('blur', $event);
    },

    clearSuggestion() {
      this.suggestion = null;
    },

    suggest(email) {
      return Mailcheck.run({
        email,
      })?.full;
    },
  },
};
</script>
